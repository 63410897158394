//
//  pdf_template.ts
//  giftcard-service
//
//  Created by Flemming Pedersen on 13/03/2019.
//  Copyright © 2019 Ka-ching. All rights reserved.
//

import * as _ from "lodash"
import { Background } from "./background"
import { Margins } from "./margins"
import { PDFMetadata } from "./pdf_metadata"
import { PDFTemplateLine } from "./pdf_template_line"

export class PDFTemplate {

    public static fromJSON(json: any): PDFTemplate {
        const lines: PDFTemplateLine[] = []
        json.lines.forEach((lineJSON: any) => {
            const line = PDFTemplateLine.fromJSON(lineJSON)
            if (line) {
                lines.push(line)
            }
        })

        const pdfTemplate = new PDFTemplate(lines)
        pdfTemplate.pageSize = json.page_size
        pdfTemplate.alignment = json.alignment
        pdfTemplate.font = json.font
        pdfTemplate.textColor = json.text_color
        pdfTemplate.lineSpacing = json.line_spacing

        const fontSize = Number(json.font_size)
        if (!isNaN(fontSize)) {
            pdfTemplate.fontSize = fontSize
        }

        if (!_.isNil(json.font_map)) {
            pdfTemplate.fontMap = new Map(Object.entries(json.font_map))
        }

        const margins = Margins.fromJSON(json.margins)
        if (!_.isNil(margins)) {
            pdfTemplate.margins = margins
        }

        const background = Background.fromJSON(json.background)
        if (!_.isNil(background)) {
            pdfTemplate.background = background
        }

        const metadata = PDFMetadata.fromJSON(json.metadata)
        if (!_.isNil(metadata)) {
            pdfTemplate.metadata = metadata
        }

        const scale = Number(json.scale)
        if (!isNaN(scale)) {
            pdfTemplate.scale = scale
        }

        return pdfTemplate
    }

    public margins?: Margins
    public background?: Background
    public textColor?: string
    public font?: string
    public alignment?: string
    public fontSize?: number
    public pageSize?: number
    public fontMap: Map<string, string>
    public lines: PDFTemplateLine[]
    public metadata?: PDFMetadata
    public lineSpacing?: number
    public scale?: number

    constructor(lines: PDFTemplateLine[]) {
        this.lines = lines
        this.fontMap = new Map()
    }

    public toJSON(): any {
        const map: any = {}
        this.fontMap.forEach((value, key) => {
            map[key] = value
        })

        const json: any = {
            font_map: map,
            lines: this.lines.map((line) => line.toJSON()),
        }

        if (!_.isNil(this.alignment)) {
            json.alignment = this.alignment
        }

        if (!_.isNil(this.font)) {
            json.font = this.font
        }

        if (!_.isNil(this.background)) {
            json.background = this.background.toJSON()
        }

        if (!_.isNil(this.fontSize)) {
            json.font_size = this.fontSize
        }

        if (!_.isNil(this.margins)) {
            json.margins = this.margins.toJSON()
        }

        if (!_.isNil(this.pageSize)) {
            json.page_size = this.pageSize
        }

        if (!_.isNil(this.textColor)) {
            json.text_color = this.textColor
        }

        if (!_.isNil(this.lineSpacing)) {
            json.line_spacing = this.lineSpacing
        }

        if (!_.isNil(this.scale)) {
            json.scale = this.scale
        }

        return json
    }

}
