import * as _ from "lodash"

export class MarketAmount {
    value: number | _.Dictionary<number>

    constructor(value: number | _.Dictionary<number>) {
        this.value = value
    }

    json(): any {
        switch (typeof this.value) {
            case "number":
                return this.value
            case "object":
                if (Object.keys(this.value).length === 1) {
                    return this.value[Object.keys(this.value)[0]]
                } else {
                    return this.value
                }
            default:
                return null
        }
    }

    static create(value: number, market: string | null): MarketAmount {
        const amount = new MarketAmount(value)
        if (market) {
            amount.explode([market])
        }
        return amount
    }

    amount(market: string | null): number | null {
        switch (typeof this.value) {
            case "number":
                return this.value as number
            case "object": {
                const dict = this.value as _.Dictionary<number>
                if (_.isNil(market)) {
                    if (Object.keys(dict).length === 1) {
                        return dict[Object.keys(dict)[0]]
                    } else {
                        return null
                    }
                }
                return dict[market] ?? null
            }
            default:
                return null
        }
    }

    // Mutating
    explode(markets: string[]) {
        switch (typeof this.value) {
            case "number": {
                const value = this.value as number
                const dict: _.Dictionary<number> = {}
                for (const key of markets) {
                    dict[key] = value
                }
                this.value = dict
                break
            }
            case "object": {
                // Already exploded
                for (const key of Object.keys(this.value)) {
                    if (!markets.includes(key)) {
                        delete this.value[key]
                    }
                }
                break
            }
            default:
                break
        }
    }

    // Mutating
    implode() {
        switch (typeof this.value) {
            case "number":
                // Already imploded
                return

            case "object": {
                const dict = this.value as _.Dictionary<number>
                const keys = Object.keys(dict)
                if (keys.length >= 1) {
                    this.value = dict[keys[0]]
                } else {
                    // If there are no keys in _.Dictionary we
                    // have an inconsistency. It will be serialized to null,
                    // so it is not a critical error
                }
                break
            }
            default:
                break
        }
    }

    setMarkets(markets: string[]) {
        if (markets.length < 1) {
            this.implode()
        } else {
            this.explode(markets)
        }
    }

    hasAmountFor(market: string | null): boolean {
        switch (typeof this.value) {
            case "number":
                return true

            case "object": {
                const dict = this.value as _.Dictionary<number>
                if (_.isNil(market)) {
                    if (Object.keys(dict).length === 1) {
                        return true
                    } else {
                        return false
                    }
                } else {
                    return _.isNil(dict[market]) ? false : true
                }
            }
            default:
                return false
        }
    }

    setAmount(value: number | null, market: string | null): MarketAmount | null {
        const clone = _.cloneDeep(this)
        if (market === null) {
            clone.implode()
        }

        switch (typeof clone.value) {
            case "number": {
                if (value === null) {
                    return null
                }
                clone.value = value
                break
            }

            case "object": {
                // Should not happen, as we just imploded value in case of missing market
                if (market === null) { return null }

                const dict = clone.value as _.Dictionary<number>
                if (market === null) {
                    clone.implode()
                }
                if (value === null) {
                    delete dict[market]
                } else {
                    dict[market] = value
                }
                if (Object.keys(dict).length === 0) {
                    return null
                }
                break
            }
            default:
                break
        }
        return clone
    }

    // Mutating
    removeMarket(market: string, remaining: string[]) {
        switch (typeof this.value) {
            case "number":
                break
            case "object": {
                const dict = this.value as _.Dictionary<number>
                delete dict[market]
                this.value = dict
                break
            }
            default:
                break
        }
        if (remaining.length <= 1) {
            this.implode()
        }
    }
}