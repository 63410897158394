import React, { useState } from "react";
import { StripedTable } from "../../StripedTable";
import { Col, Row } from "../../wrappers";
import { AssetType, GiftcardAssetDropzone } from "../GiftcardAssetDropzone";
import { ConfirmDeleteButton } from "../../ConfirmDeleteButton";
import { GiftcardConfig } from "./GiftcardTypeList";
import { GiftcardImageModal } from "./GiftcardImageModal";

interface AssetProps {
    giftcardConfig: GiftcardConfig
    fonts: string[]
    images: string[]
    updatedImages: (images: string[]) => void
    updatedFonts: (fonts: string[]) => void
    addedFont: (name: string) => void
    addedImage: (name: string) => void
    imagesInUse: Set<string>
    fontsInUse: Set<string>
    ready: boolean
}

export function Asset(props: AssetProps) {
    return (
        <div>
            <br></br>
            <h3>Asset upload </h3>
            <p> You can upload fonts or images, which will be available for all gift card pdf templates </p>
            <Row>
                <Col sm={6}>
                    <GiftcardAssetDropzone giftcardConfig={props.giftcardConfig} assetType="image" addedFileName={props.addedImage} />
                    <AssetList ready={props.ready} inUse={props.imagesInUse} assets={props.images} assetType="image" giftcardConfig={props.giftcardConfig} updatedAsset={props.updatedImages} resetDeletion={props.addedImage}></AssetList>
                </Col>
                <Col sm={6}>
                    <GiftcardAssetDropzone giftcardConfig={props.giftcardConfig} assetType="font" addedFileName={props.addedFont} />
                    <AssetList ready={props.ready} inUse={props.fontsInUse} assets={props.fonts} assetType="font" giftcardConfig={props.giftcardConfig} updatedAsset={(asset) => props.updatedFonts(asset)} resetDeletion={props.addedFont}></AssetList>
                </Col>
            </Row>
            <br></br>
            <br></br>
        </div>
    );
}

interface AssetListProps {
    assets: string[];
    assetType: AssetType;
    giftcardConfig: GiftcardConfig
    inUse: Set<string>
    updatedAsset: (assets: string[]) => void
    resetDeletion: (asset: string) => void
    ready: boolean
}

function isInUse(props: AssetListProps, name: string): boolean {
    return props.inUse.has(name)
}

function AssetList(props: AssetListProps) {
    const [imageURL, setImageURL] = useState<string>()
    if (props.assets.length > 0) {
        return <StripedTable>
            {imageURL && <GiftcardImageModal giftcardConfig={props.giftcardConfig} imageURL={imageURL} onOk={() => setImageURL(undefined)}></GiftcardImageModal>}
            <thead>
                <tr>
                    <th style={{ fontWeight: "bold" }}> {props.assetType === "image" ? "Images" : "Fonts"} </th>
                    <th style={{ fontWeight: "bold" }}> Status </th>
                    <th style={{ fontWeight: "bold" }}> Delete </th>
                </tr>
            </thead>
            <tbody>
                {props.assets.map((name: any) => {
                    return (
                        <tr key={name} onClick={() => setImageURL(name)}>
                            <td>{name}</td>
                            <td>{!props.ready ? "Loading..." : (isInUse(props, name) ? "Asset is in use" : "Asset is not in use") }</td>
                            <td className="narrow">
                                <ConfirmDeleteButton
                                    disabled={!props.ready || isInUse(props, name)}
                                    message={`Really delete ${name}?`}
                                    onDelete={async () => {
                                        const currentAssets = [...props.assets]
                                        const index = currentAssets.indexOf(name)
                                        const deletedAsset = currentAssets.splice(index, 1)[0]
                                        props.updatedAsset([...currentAssets])
                                        try {
                                            await deleteAsset(name, props.assetType, props.giftcardConfig)
                                        } catch {
                                            props.resetDeletion(deletedAsset)
                                        }
                                    }} />
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </StripedTable>;
    } else {
        return <div></div>;
    }
}

async function deleteAsset(name: string, assetType: AssetType, giftcardConfig: GiftcardConfig) {
    const assetPath = assetType == "image" ? `/api/v1/asset/image/${name}` : `/api/v1/asset/font/${name}`
    const url = giftcardConfig.baseURL + assetPath

    const headers = new Headers()

    headers.append("token", giftcardConfig.token)
    headers.append("uid", giftcardConfig.uid)

    const requestInit: RequestInit = {
        method: "DELETE",
        headers: headers,
    }

    const request = new Request(url, requestInit)
    const response = await fetch(request)

    if (!response.ok) {
        throw new Error()
    }
}